<template>
  <component :is="contentComponent" @updateMeta="updatePageMetadata" />
</template>

<script>

const _m = () => import('@/pages/home/m.vue') //代码切割
const _pc = () => import('@/pages/home/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
      changeCity: false,
      currentCity: 'Major Cities', // 初始城市名称
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "adsensConfig"
    ])
  },
  metaInfo () {
    return {
      title: `Current Time in ${this.currentCity} | TimeInCities.com`,
      meta: [
        {
          name: 'description',
          content: this.changeCity ? `Stay updated with the current time in ${this.currentCity} on TimeInCities.com. Find accurate and reliable time information, along with additional details about the city’s time zone, daylight saving changes, and local time-related tips.` : `Discover the current time in major cities around the world. TimeInCities.com provides accurate and up-to-date time information for cities globally. Plan your meetings, travels, and calls with ease.`,
        },
      ],
    };
  },
  async mounted () {
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
  methods: {
    updatePageMetadata (cityName) {
      this.currentCity = cityName; // 更新当前城市名称
      this.changeCity = true
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        // this.$eventrack('adscript_loaded', 'expose');
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        // this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        // this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      // this.$eventrack('adscript_add_success', 'expose');
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
    },
  },
};
</script>
